@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --sidebar-min-width: 25rem;
  --sidebar-max-width: 30.3125rem;
  --sidebar-preferred-width: 33vw;
  --submenu-width: clamp(11.375rem, -3.5460526312rem + 16.5vw, 19.25rem);
  --sidebar-width: clamp(
    var(--sidebar-min-width),
    var(--sidebar-preferred-width),
    var(--sidebar-max-width)
  );
  --nav-width: 4.5rem;
  --side-padding: 7.5rem;
  --body-font: "system-ui", apple-system, BlinkMacSystemFont, "Helvetica Neue",
    Arial;

  /*Colours*/
  --primary-color: var(--color-core-primary-main, #4e8ff6);
  --primary-color-highlight: #e2edfd;
  --primary-color-faded: #f1f6fe;
  --primary-h: 217;
  --primary-s: 90%;
  --primary-l: 64%;
  --primary-l-dark: 45%;

  --secondary-color: #f6894e;
  --secondary-h: 21;
  --secondary-s: 90%;
  --secondary-l: 64%;
  --secondary-l-dark: 45%;

  --dark-blue: #2d3741;
  --charcoal: hsl(345, 5%, 15%);
  --near-black: #1b1718;
  --darkest-grey: #373233;
  --grey: var(--grey-1);
  --light-grey: var(--grey-6);
  --lighter-grey: #92999e;

  --single: var(--primary-color);
  --bundle: #b95f89;
  --variant: #8783d1;
  --bundle-light: #f9ebf1;
  --variant-light: #efeeff;
  --digital: #b45a19;

  --neutral: #8783d1;
  --red: #cc6666;
  --error-h: 0;
  --error-s: 50%;
  --error-l: 60%;
  --error-l-dark: 40%;
  --error-highlight: #f5e0e0;

  --success: #66cc9a;
  --success-dark: #27754f;
  --success-light: #e0f5eb;

  --success-h: 152;
  --success-s: 50%;
  --success-l: 60%;

  --warning: #edb013;
  --warning-dark: #c29011;
  --warning-light: #fbefd0;

  --danger: #cc6666;
  --danger-dark: #993333;
  --danger-light: #f5e0e0;
  --danger-h: 0;
  --danger-s: 50%;
  --danger-l: 60%;
  --danger-l-dark: 40%;

  --special: #66cc9a;
  --special-h: 151;
  --special-s: 50%;
  --special-l: 60%;
  --special-l-dark: 32%;
  --special-l-light: 92%;

  --warning: #f6894e;
  --warning-h: 21;
  --warning-s: 90%;
  --warning-l: 64%;
  --orange-l-dark: 45%;
  --orange-l-light: 45%;

  --purple: #8b65f5;
  --purple-h: 256;
  --purple-s: 88%;
  --purple-l: 68%;
  --purple-l-light: 92%;
  --purple-l-dark: 48%;

  --info-h: 256;
  --info-s: 88%;
  --info-l: 68%;

  --facebook: #1877f2;
  --bazaar-voice: #00ab8e;

  --grey-50: #f5f6f9;
  --grey-100: #ebedf5;
  --grey-200: #e1e3eb;
  --grey-300: #c4c5cc;
  --grey-400: #a6a8ad;
  --grey-500: #898a8f;
  --grey-600: #6c6c70;
  --grey-700: #4e4f52;
  --grey-800: #313133;
  --grey-850: #222324;
  --grey-900: #141414;

  --yellow: lemonchiffon;

  --border-color: var(--grey-7);

  --nav-item-width: calc(var(--space-unit) * 68);
  --background-color: #f7f9fc;
  --text-color: var(--grey-1);
  --heading-color: var(--charcoal);
  --header-bg: white;
  --container-bg: white;

  --dropdown-shadow: 0px 1px 2px rgba(6, 48, 115, 0.05),
    0px 4px 10px rgba(6, 48, 115, 0.2);
  --dropdown-shadow-large: 0px 1px 2px rgba(6, 48, 115, 0.05),
    0px 20px 40px rgba(0, 0, 0, 0.2);

  --global-radius: 0.28571428571rem;

  --panel-background: white;

  --keyboard-focus-shadow: 0px 0px 0px 3px rgba(237, 176, 19, 0.4);

  --keyboard-focus-shadow-with-border: 0px 0px 0px 1px var(--grey-850),
    0px 0px 0px 3px rgba(237, 176, 19, 0.4);

  --border-color: var(--grey-200);
  --max-width: 1600px;

  --fb-message-danger-bg: #f5e0e0;
  --fb-message-danger-text: #993333;
  --fb-message-success-bg: #e0f5eb;
  --fb-message-success-text: #27754f;

  --tab-background: var(--grey-200);
}

/* @media (prefers-color-scheme: dark) {
    :root {
        --panel-background: rgb(22, 27, 34);
    }
} */
*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  font-size: 16px;
  color: var(--grey-850);
  background: var(--background-color);
}

#__next:has(#builder) {
  height: 100vh;
}
#__next > div:first-child {
  width: 100%;
  height: 100%;
}
/* A few resets*/

a {
  color: inherit;
  text-decoration: none;
}

input {
  font-size: 1em;
}

[hidden] {
  display: none !important;
}

img {
  max-width: 100%;
  height: auto;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h1 {
  font-size: 1.5rem;
}

/* suppress focus ring on form controls for mouse users */

[data-whatintent="mouse"] *:focus {
  outline: none;
}

.u-screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

:root {
  --device-chrome: var(--grey-300);
  --device-chrome-accent: var(--grey-700);

  --button-tertiary-h: 228;
  --button-tertiary-s: 33%;
  --button-tertiary-l: 100%;
  --button-tertiary-color: var(--grey-500);
  --button-tertiary-border-color: var(--grey-200);
  --button-tertiary-l-dark: 90%;
}
