@keyframes dropdown-scale {
  0% {
    opacity: 0;
    transform: translateY(-1rem) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translateY(0%) scale(1);
  }
}
