.ui {
  margin-block: 1.5rem;
  position: relative;
}
.ui:not(.ui--flatten)::after {
  content: "";
  border: 2px solid var(--color-core-destructive-main);
  top: -0.75rem;
  left: -1.5rem;
  right: -1.5rem;
  bottom: -0.75rem;
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.ui--level-0 :where(.ui, .title) {
  margin-block: 1.5rem;
}

.ui:not(.ui--object) + .ui--object:not(.ui--group):not(.ui--colour) {
  border-top: 1px solid var(--border-color);
}
.ui--object:not(.ui--group):not(.ui--colour):not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}

.sidebar__content > .ui {
  padding-inline: 1.5rem;
}

.sidebar__content > .ui::after {
  left: 0;
  right: 0;
}
